import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import icecream_banner_blur from "./assets/images/home_banner/bluricecreambanner.webp";
import brownie_banner_blur from "./assets/images/home_banner/brownie_banner_blur.webp";
import shake_banner_blur from "./assets/images/home_banner/shake_banner_blur.webp";
import shrikhand_banner_blur from "./assets/images/home_banner/shrikhand_banner_blur.webp";
import conecandy_banner_blur from "./assets/images/home_banner/conecandy_banner_blur.webp";
import sundae_banner_blur from "./assets/images/home_banner/sundae_banner_blur.webp";
import icecream_banner from "./assets/images/home_banner/icecream_banner.webp";
import shrikhand_banner from "./assets/images/home_banner/shrikhand_banner.webp";
import brownie_banner from "./assets/images/home_banner/brownie_banner.webp";
import conecandy_banner from "./assets/images/home_banner/conecandy_banner.webp";
import sundae_banner from "./assets/images/home_banner/sundae_banner.webp";
import momshake_banner from "./assets/images/home_banner/shake_banner.webp";
import header_image from "./assets/images/home_banner/Menu.webp";
import discoveryimage41 from "./assets/images/discovery/discoveryimage41.webp";
import discoveryimage42 from "./assets/images/discovery/discoveryimage42.webp";
import discoveryimage43 from "./assets/images/discovery/discoveryimage43.webp";

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const preloadStylesheet = (url) => {
  const link = document.createElement("link");
  link.rel = "preload";
  link.href = url;
  link.as = "style";
  link.onload = () => {
    // Once the stylesheet is preloaded, apply it
    link.rel = "stylesheet";
  };
  document.head.appendChild(link);
};

// const preloadVideos = (videoUrls) => {
//   videoUrls.forEach((url) => {
//     const video = document.createElement("video");
//     video.src = url;
//     video.preload = "auto"; // Preload video data
//     // Optionally, you can append it to the DOM temporarily to ensure it's loaded
//     document.head.appendChild(video);
//     video.oncanplaythrough = () => {
//       document.head.removeChild(video);
//     };
//   });
// };

const AppWrapper = () => {
  useEffect(() => {
    preloadImages([
      icecream_banner_blur,
      brownie_banner_blur,
      shake_banner_blur,
      shrikhand_banner_blur,
      conecandy_banner_blur,
      sundae_banner_blur,
      icecream_banner,
      shrikhand_banner,
      conecandy_banner,
      brownie_banner,
      sundae_banner,
      momshake_banner,
      header_image,
      discoveryimage41,
      discoveryimage42,
      discoveryimage43,
    ]);

    preloadStylesheet("./assets/css/style.css");
    preloadStylesheet("./assets/css/style.bundle.css");

    // preloadVideos([
    //   "./assets/images/magicalmoments/video1.mp4",
    //   "./assets/images/magicalmoments/video2.mp4",
    //   "./assets/images/magicalmoments/video3.mp4",
    //   "./assets/images/magicalmoments/video4.mp4",
    //   "./assets/images/magicalmoments/video5.mp4",
    //   "./assets/images/magicalmoments/video6.mp4",
    //   "./assets/images/magicalmoments/video7.mp4",
    //   "./assets/images/magicalmoments/video8.mp4",
    //   // Add more video URLs here
    // ]);
  }, []);

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker.register("./service-worker.js").then(
        (registration) => {
          // console.log("Service Worker registered with scope:", registration.scope);
        },
        (err) => {
          // console.log("Service Worker registration failed:", err);
        }
      );
    });
  }

  return (
    <React.StrictMode>
      <Router basename={"/"}>
        <App />
      </Router>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<AppWrapper />);
}
