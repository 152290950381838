import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PreLoader from "../pages/components/PreLoader"; // Ensure you have a PreLoader component

// Lazy loading pages
const Home = lazy(() => import("../pages/Home"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const FranchiseInquiry = lazy(() => import("../pages/FranchiseInquiry"));
const DeliciousDiscovery = lazy(() => import("../pages/DeliciousDiscovery"));
const ConnectWthUs = lazy(() => import("../pages/ConnectWthUs"));
const Career = lazy(() => import("../pages/Career"));
const MagicalMoments = lazy(() => import("../pages/MagicalMoments"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));

const PrivateRoutes = () => {
  return (
    <Suspense fallback={<PreLoader />}>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="aboutus" exact element={<AboutUs />} />
        <Route path="franchiseinquiry" exact element={<FranchiseInquiry />} />
        <Route path="deliciousdiscovery" exact element={<DeliciousDiscovery />} />
        <Route path="contactus" exact element={<ConnectWthUs />} />
        <Route path="career" exact element={<Career />} />
        <Route path="magicalmoments" exact element={<MagicalMoments />} />
        <Route path="privacypolicy" exact element={<PrivacyPolicy />} />
        <Route path="*" exact element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};

export { PrivateRoutes };
