// import "./App.css";
// import { PrivateRoutes } from "./routes/PrivateRoutes";
// import "../src/assets/css/style.bundle.css";
// // import '../src/assets/css/bootstrap.min.css'
// import "../src/assets/css/bootstrap.min.css.map";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../src/assets/css/style.css";
// import "../src/assets/css/fontawesome.min.css";
// import './pages/components/PreLoader'

// function App() {

//   return (
//       <PrivateRoutes />
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PrivateRoutes } from "./routes/PrivateRoutes";
import PreLoader from "./pages/components/PreLoader";
import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/css/style.bundle.css";
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/bootstrap.min.css.map";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/fontawesome.min.css";
import "./pages/components/PreLoader";
import "animate.css"; 
import WOW from "wow.js/dist/wow.js";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Detect route changes


  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow", // Target elements with the "wow" class
      animateClass: "animate__animated", // Animation class from Animate.css
      offset: 100, // Trigger animation when 100px away from the viewport
      mobile: true, // Enable animations on mobile
      live: true, // Observe dynamically added elements
    });
  
    wow.init();
  
    // Reinitialize WOW.js on route changes
    return () => wow.sync();
  }, [location]);
  
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false); 
    }, 800); 

    return () => clearTimeout(timer);
  }, [location]); // Trigger loader on route change

  if (isLoading) {
    return <PreLoader />;
  }

  return <PrivateRoutes />;
}

export default App;
